import transport from '@/services/api/engagement/transport';
import * as methods from '@/services/api/constants';

/**
 * Гайд по именованию ресурсов
 * https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 *
 */

export const surveyTeamNotify = ({ teamId }, ...options) => transport({
  url: 'admin/surveys/active/notification-reminders',
  method: methods.HTTP_POST,
  data: { teamId },
  ...options,
});

export const surveyTeamNotificationsGet = (data = {}, ...options) => transport({
  url: 'admin/surveys/active/notification-reminders',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const teamChildrenGet = (teamId, data = {}, options) => transport({
  url: `admin/teams/${teamId}/children`,
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const teamsChildrenGet = (options) => transport({
  url: 'admin/teams/children',
  method: methods.HTTP_GET,
  ...options,
});

export const teamGetByName = (data = {}, options) => transport({
  url: 'admin/teams',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const teamGetTree = (options) => transport({
  url: 'admin/teams/tree',
  method: methods.HTTP_GET,
  ...options,
});

export const teamsCollapsedGet = (options) => transport({
  url: '/admin/teams/ids/merge',
  method: methods.HTTP_POST,
  ...options,
});

export const surveyAnalyticsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}`,
  methods: methods.HTTP_GET,
  ...options,
});

export const surveyConstructorAnalyticsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/constructor`,
  methods: methods.HTTP_GET,
  ...options,
});

export const surveyBurnoutAnalyticsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/burnout`,
  methods: methods.HTTP_GET,
  ...options,
});

export const currentSurveyGet = (data = {}, options) => transport({
  url: 'admin/analytics/surveys/current',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const surveyTeamFactorsGet = (surveySlug, options) => transport({
  url: `admin/analytics/surveys/${surveySlug}/factors`,
  method: methods.HTTP_GET,
  ...options,
});

export const surveyTeamFactorsComparisonGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/factor-by-team`,
  method: methods.HTTP_POST,
  ...options,
});

export const surveyTeamTimingsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/timings-by-team`,
  method: methods.HTTP_GET,
  ...options,
});

export const factorsQuestionsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/factor-questions`,
  method: methods.HTTP_GET,
  ...options,
});

export const factorsStructureGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/factor-groups`,
  method: methods.HTTP_GET,
  ...options,
});

export const factorsHistoryGet = (data = {}, options) => transport({
  url: '/admin/company-historical-data',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const surveyFilterMetaValuesGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/meta-filters`,
  method: methods.HTTP_GET,
  ...options,
});

export const surveyOpenedQuestionsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/open-questions`,
  method: methods.HTTP_GET,
  ...options,
});

export const surveyOpenedQuestionsAnswersGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/open-question-answers`,
  method: methods.HTTP_GET,
  ...options,
});

export const getXlsxReports = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/xlsx-reports/open-questions`,
  method: methods.HTTP_GET,
  responseType: 'blob',
  ...options,
});

export const surveyPriorityFactorsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/priority_factors`,
  method: methods.HTTP_GET,
  ...options,
});

export const surveyCommentsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/comments`,
  method: methods.HTTP_POST,
  ...options,
});

export const surveyOpenQuestionAnswersGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/constructor-open-question-answers`,
  method: methods.HTTP_GET,
  ...options,
});

export const surveyOtherAnswersGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/other-answers`,
  method: methods.HTTP_POST,
  ...options,
});

// TODO уменьшить простыню, разделить на неск файлов-сервисов e.g.: admin.surveys, admin.teams, admin.analytics
// конструктор
export const surveyListsGet = (data = {}, options) => transport({
  url: '/admin/surveys',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const surveyDraftsGet = (data = {}, options) => transport({
  url: '/admin/surveys/drafts',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const surveyTrashedGet = (data = {}, options) => transport({
  url: '/admin/surveys/trashed',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const surveyCreate = (data = {}, ...options) => transport({
  url: '/admin/surveys',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const surveyDelete = (options) => transport({
  url: '/admin/surveys',
  method: methods.HTTP_DELETE,
  ...options,
});

export const surveyConstructorGet = (surveySlug, options) => transport({
  url: `/admin/constructor/surveys/${surveySlug}`,
  methods: methods.HTTP_GET,
  ...options,
});

export const pageFullInfoGet = (pageId, options) => transport({
  url: `/admin/constructor/pages/full_info/${pageId}`,
  methods: methods.HTTP_GET,
  ...options,
});

export const surveyPagesGet = (surveySlug, options) => transport({
  url: `/admin/constructor/pages/${surveySlug}`,
  methods: methods.HTTP_GET,
  ...options,
});

export const surveyUpdate = (surveySlug, data = {}, ...options) => transport({
  url: `/admin/surveys/${surveySlug}`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const surveyFinishAtUpdate = (surveySlug, data = {}, ...options) => transport({
  url: `/admin/surveys/${surveySlug}/finish_at`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const surveyViewingRightsUpdate = (surveySlug, data = {}, ...options) => transport({
  url: `/admin/surveys/${surveySlug}/viewing_rights`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const surveyTranslationsUpdate = (surveySlug, data = {}, ...options) => transport({
  url: `/admin/translations/surveys/${surveySlug}`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const surveyDuplicate = (surveySlug, ...options) => transport({
  url: `/admin/surveys/${surveySlug}/duplicate`,
  method: methods.HTTP_POST,
  ...options,
});

export const surveyTrash = (surveySlug, ...options) => transport({
  url: `/admin/surveys/${surveySlug}/trash`,
  method: methods.HTTP_DELETE,
  ...options,
});

export const surveyPublish = (surveySlug, ...options) => transport({
  url: `/admin/surveys/${surveySlug}/publish`,
  method: methods.HTTP_POST,
  ...options,
});

export const surveyActivate = (surveySlug, ...options) => transport({
  url: `/admin/surveys/${surveySlug}/start_now`,
  method: methods.HTTP_POST,
  ...options,
});

export const surveyDraft = (surveySlug, ...options) => transport({
  url: `/admin/surveys/${surveySlug}/back_to_draft`,
  method: methods.HTTP_POST,
  ...options,
});

export const surveyDraftFromTrash = (options) => transport({
  url: '/admin/surveys/back_to_draft',
  method: methods.HTTP_POST,
  ...options,
});

export const templatesGet = (options) => transport({
  url: '/admin/templates',
  method: methods.HTTP_GET,
  ...options,
});

export const surveyTemplateGet = (templateId, options) => transport({
  url: `/admin/templates/${templateId}`,
  method: methods.HTTP_GET,
  ...options,
});

export const surveyTemplateCreate = (data = {}, ...options) => transport({
  url: '/admin/templates',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const surveyTemplateDelete = (id, ...options) => transport({
  url: `/admin/templates/${id}`,
  method: methods.HTTP_DELETE,
  ...options,
});

export const surveyTemplateEdit = (id, data) => transport({
  url: `/admin/templates/${id}`,
  method: methods.HTTP_PUT,
  data,
});

export const adminPagesGet = (options) => transport({
  url: '/admin/pages',
  method: methods.HTTP_GET,
  ...options,
});

export const adminPagesPost = (data = {}, options) => transport({
  url: '/admin/pages',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminPagesUpdate = (pageId, data = {}, options) => transport({
  url: `/admin/pages/${pageId}`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const adminPagesDuplicate = (pageId, data = {}, options) => transport({
  url: `/admin/pages/${pageId}/duplicate`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminPagesDelete = (pageId, data = {}, options) => transport({
  url: `/admin/pages/${pageId}`,
  method: methods.HTTP_DELETE,
  data,
  ...options,
});

export const surveyImageDelete = (surveySlug, data = {}, options) => transport({
  url: `/admin/surveys/${surveySlug}/image_trash`,
  method: methods.HTTP_DELETE,
  data,
  ...options,
});

export const branchingSettingsPost = (data = {}, options) => transport({
  url: '/admin/branching_settings',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const blockCreate = (data = {}, options) => transport({
  url: '/admin/blocks',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const blockUpdate = (id, data = {}, options) => transport({
  url: `/admin/blocks/${id}`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});
export const blockTranslate = (id, data = {}, options) => transport({
  url: `/admin/translations/blocks/${id}`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const blockDelete = (id, data = {}, options) => transport({
  url: `/admin/blocks/${id}`,
  method: methods.HTTP_DELETE,
  data,
  ...options,
});

export const blockCopy = (id, data = {}, options) => transport({
  url: `/admin/blocks/${id}/copy`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const questionCreate = (data = {}, options) => transport({
  url: '/admin/questions',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const questionUpdate = (id, data = {}, options) => transport({
  url: `/admin/questions/${id}`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const surveyXlsxReportPost = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/xlsx-reports-export`,
  method: methods.HTTP_POST,
  ...options,
});

export const templatesImageGet = (data = {}, options) => transport({
  url: '/admin/image_templates',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const templatesQuestionGet = (data = {}, options) => transport({
  url: '/admin/question_templates',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const practicesGet = (data = {}, options) => transport({
  url: '/admin/hr_practices',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const practiceGet = (id, options) => transport({
  url: `/admin/hr_practices/${id}`,
  method: methods.HTTP_GET,
  ...options,
});

export const practiceCreatePost = (data = {}) => transport({
  url: '/admin/hr_practices',
  method: methods.HTTP_POST,
  data,
});

export const practiceUpdate = (id, data) => transport({
  url: `/admin/hr_practices/${id}`,
  method: methods.HTTP_PUT,
  data,
});

export const practiceDelete = (id, options) => transport({
  url: `/admin/hr_practices/${id}`,
  method: methods.HTTP_DELETE,
  ...options,
});

export const practiceSetVisible = (id, options) => transport({
  url: `/admin/hr_practices/${id}/set_visible`,
  method: methods.HTTP_POST,
  ...options,
});

export const practiceSetInvisible = (id, options) => transport({
  url: `/admin/hr_practices/${id}/set_invisible`,
  method: methods.HTTP_POST,
  ...options,
});

export const xlsxReportStatusGet = (reportId) => transport({
  url: `/admin/analytics/surveys/${reportId}/report_status`,
  method: methods.HTTP_GET,
});

export const surveyXlsxReportGet = (surveySlug, reportId) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/xlsx-reports/${reportId}/download`,
  method: methods.HTTP_GET,
  responseType: 'blob',
});

export const surveysTimelineGet = (surveySlug, options) => transport({
  url: `/admin/surveys/${surveySlug}/timeline`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminSurveysGet = () => transport({
  url: '/admin/admin_area/surveys',
  method: methods.HTTP_GET,
});

export const stopSurveyPost = (surveySlug) => transport({
  url: `/admin/surveys/${surveySlug}/stop_now`,
  method: methods.HTTP_POST,
});

export const surveyRequest = (data = {}, options) => transport({
  url: '/admin/survey_requests',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const tagsGet = (options) => transport({
  url: '/admin/tags',
  ...options,
});

export const heatmapCreate = (data) => transport({
  url: '/admin/heatmaps',
  method: methods.HTTP_POST,
  data,
});

export const heatmapByIdGet = (id) => transport({
  url: `/admin/heatmaps/${id}`,
  method: methods.HTTP_GET,
});

export const historicalDataGet = (surveySlug, options) => transport({
  url: `/admin/company-historical-data/${surveySlug}`,
  method: methods.HTTP_GET,
  ...options,
});

export const metaInfoGet = () => transport({
  url: '/admin/meta_info',
  method: methods.HTTP_GET,
});

export const metaQuestionsUpdate = (data, options) => transport({
  url: '/admin/survey_meta_questions',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const userGroupCreate = (data) => transport({
  url: '/admin/user_groups/',
  method: methods.HTTP_POST,
  data,
});

export const userGroupUpdate = (id, data) => transport({
  url: `/admin/user_groups/${id}`,
  method: methods.HTTP_PUT,
  data,
});

export const userGroupDelete = (id) => transport({
  url: `/admin/user_groups/${id}`,
  method: methods.HTTP_DELETE,
});

export const rolesListGet = (options) => transport({
  url: '/admin/roles',
  method: methods.HTTP_GET,
  ...options,
});

export const userGroupsGet = (options) => transport({
  url: '/admin/user_groups',
  method: methods.HTTP_GET,
  ...options,
});

export const userInfoGet = (options) => transport({
  url: '/admin/me',
  method: methods.HTTP_GET,
  ...options,
});

export const companyUsersGet = (options) => transport({
  url: '/admin/users',
  method: methods.HTTP_GET,
  ...options,
});

export const userGroupsByIdGet = (id) => transport({
  url: `/admin/user_groups/${id}`,
  method: methods.HTTP_GET,
});

export const pptxReportPost = (surveySlug, options) => transport({
  url: `/admin/surveys/${surveySlug}/pptx/pptx-export`,
  method: methods.HTTP_POST,
  ...options,
});

export const pptxReportStatusGet = (suveySlug, id) => transport({
  url: `/admin/surveys/${suveySlug}/pptx/${id}/status`,
  method: methods.HTTP_GET,
});

export const pptxReportGet = (surveySlug, id) => transport({
  url: `/admin/surveys/${surveySlug}/pptx/${id}/download`,
  method: methods.HTTP_GET,
  responseType: 'blob',
});

export const surveyAdditionalQuestionsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/additional_questions`,
  method: methods.HTTP_POST,
  ...options,
});

export const surveyHasQuestionsGet = (surveySlug, options) => transport({
  url: `/admin/constructor/surveys/${surveySlug}/has_any_questions`,
  method: methods.HTTP_GET,
  ...options,
});

export const surveyAllLanguagesFilledGet = (surveySlug, options) => transport({
  url: `/admin/constructor/surveys/${surveySlug}/all_languages_filled`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminFactorsGet = (options) => transport({
  url: '/admin/factors',
  method: methods.HTTP_GET,
  ...options,
});

export const analyticsTeamTreeGet = (options) => transport({
  url: '/admin/analytics/teams/tree',
  method: methods.HTTP_GET,
  ...options,
});

export const surveyCsiAnalyticsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/csi`,
  method: methods.HTTP_GET,
  ...options,
});

export const surveyRequestPost = (data = {}) => transport({
  url: '/admin/surveys/request',
  method: methods.HTTP_POST,
  data,
});

export const userSurveysGet = (options) => transport({
  url: '/users/surveys',
  method: methods.HTTP_GET,
  ...options,
});

export const adminFactorsGroupsGet = () => transport({
  url: '/admin/factors_groups',
  method: methods.HTTP_GET,
});

export const adminFactorPost = (data = {}) => transport({
  url: '/admin/factors',
  method: methods.HTTP_POST,
  data,
});

export const adminFactorDelete = (id) => transport({
  url: `/admin/factors/${id}`,
  method: methods.HTTP_DELETE,
});

export const adminFactorUpdate = (id, data = {}) => transport({
  url: `/admin/factors/${id}`,
  method: methods.HTTP_PUT,
  data,
});

export const adminFactorVisibilitySet = (id, data = {}) => transport({
  url: `/admin/factors/visibility/${id}`,
  method: methods.HTTP_PUT,
  data,
});

export const emailTemplatePost = (data = {}) => transport({
  url: '/admin/email_templates',
  method: methods.HTTP_POST,
  data,
});

export const emailTemplatePut = (id, data = {}) => transport({
  url: `/admin/email_templates/${id}`,
  method: methods.HTTP_PUT,
  data,
});

export const testEmailPost = (id, data = {}) => transport({
  url: `/admin/email_templates/${id}/test_email_message`,
  method: methods.HTTP_POST,
  data,
});

export const emailTemplateImageDelete = (id) => transport({
  url: `/admin/email_templates/${id}/trash_image`,
  method: methods.HTTP_DELETE,
});

export const practiceImageDelete = (id) => transport({
  url: `/admin/hr_practices/${id}/trash_image`,
  method: methods.HTTP_DELETE,
});

export const adminIndexesGet = (options) => transport({
  url: '/admin/indicies',
  method: methods.HTTP_GET,
  ...options,
});

export const adminExpressSurveysFilterValuesGet = (surveySlug, options) => transport({
  url: `/admin/express_surveys/analytics/${surveySlug}/filter_values`,
  method: methods.HTTP_GET,
  ...options,
});

export const rawDataExportPost = (options) => transport({
  url: '/admin/analytics/surveys/raw_data_reports/export',
  method: methods.HTTP_POST,
  ...options,
});

export const rawDataExportStatusGet = (reportId) => transport({
  url: `/admin/analytics/surveys/raw_data_reports/${reportId}/status`,
  method: methods.HTTP_GET,
});

export const expressSurveyOpenQuestionsGet = (surveySlug, options) => transport({
  url: `/admin/express_surveys/analytics/${surveySlug}/open_questions/not_nps`,
  method: methods.HTTP_GET,
  ...options,
});

export const expressSurveyNPSOpenQuestionsGet = (surveySlug, options) => transport({
  url: `/admin/express_surveys/analytics/${surveySlug}/open_questions/nps`,
  method: methods.HTTP_GET,
  ...options,
});

export const rawDataExportGet = (reportId) => transport({
  url: `/admin/analytics/surveys/raw_data_reports/${reportId}/download`,
  method: methods.HTTP_GET,
  responseType: 'blob',
});

export const expressSurveyTeamFiltersGet = (surveySlug, options) => transport({
  url: `/admin/express_surveys/analytics/${surveySlug}/filter_values`,
  method: methods.HTTP_GET,
  ...options,
});

export const expressSurveyMainResultsGet = (surveySlug, options) => transport({
  url: `/admin/express_surveys/analytics/${surveySlug}/main_results`,
  method: methods.HTTP_GET,
  ...options,
});

export const expressSurveyBusinessUnitsMainResultsGet = (surveySlug, options) => transport({
  url: `/admin/express_surveys/analytics/${surveySlug}/main_results/business_units_comparison`,
  method: methods.HTTP_GET,
  ...options,
});

export const expressSurveyNPSBasicResultsGet = (surveySlug, options) => transport({
  url: `/admin/express_surveys/analytics/${surveySlug}/nps_basic`,
  method: methods.HTTP_GET,
  ...options,
});

export const expressSurveyNPSBusinessUnitResultsGet = (surveySlug, options) => transport({
  url: `/admin/express_surveys/analytics/${surveySlug}/nps_business_unit`,
  method: methods.HTTP_GET,
  ...options,
});

export const expressSurveyFactorsBusinessUnitGet = (surveySlug, options) => transport({
  url: `/admin/express_surveys/analytics/${surveySlug}/factor_business_unit`,
  method: methods.HTTP_GET,
  ...options,
});

export const expressSurveyFactorsMainQuestionsGet = (surveySlug, options) => transport({
  url: `/admin/express_surveys/analytics/${surveySlug}/main_questions`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminComplexReportGet = (id, options) => transport({
  url: `/admin/complex_reports/${id}`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminComplexReportUpdate = (id, data = {}, options) => transport({
  url: `/admin/complex_reports/${id}`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const adminComplexReportsGet = (options) => transport({
  url: '/admin/complex_reports',
  method: methods.HTTP_GET,
  ...options,
});

export const adminComplexReportsAnalyticsKindsGet = (options) => transport({
  url: '/admin/complex_reports/analytics_kinds',
  method: methods.HTTP_GET,
  ...options,
});

export const adminComplexReportsSurveysGet = (id, options) => transport({
  url: `/admin/complex_reports/${id}/surveys`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminComplexReportCreate = (data, options) => transport({
  url: '/admin/complex_reports',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminComplexReportDelete = (id, data = {}, options) => transport({
  url: `/admin/complex_reports/${id}`,
  method: methods.HTTP_DELETE,
  data,
  ...options,
});

export const questionSentimentsGet = (questionId, options) => transport({
  url: `/admin/analytics/questions/${questionId}/comments`,
  method: methods.HTTP_GET,
  ...options,
});

export const questionAnswersGet = (questionId, options) => transport({
  url: `/admin/analytics/questions/${questionId}/answers`,
  method: methods.HTTP_GET,
  ...options,
});

export const questionClusteringGet = (questionId, options) => transport({
  url: `/admin/analytics/questions/${questionId}/clustering`,
  method: methods.HTTP_GET,
  ...options,
});

export const finalPageUpdate = (surveySlug, data = {}, options) => transport({
  url: `/admin/surveys/${surveySlug}/final_page`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const finalPageGet = (surveySlug, options) => transport({
  url: `/admin/surveys/${surveySlug}/final_page`,
  method: methods.HTTP_GET,
  ...options,
});

export const finalPageBlockDelete = (surveySlug, blockId, options) => transport({
  url: `/admin/surveys/${surveySlug}/final_blocks/${blockId}`,
  method: methods.HTTP_DELETE,
  ...options,
});

export const finalPageTranslationsUpdate = (surveySlug, data = {}, options) => transport({
  url: `/admin/translations/surveys/${surveySlug}/final_page`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const orgstructurePagesUpdate = (surveySlug, data = {}, options) => transport({
  url: `/admin/surveys/${surveySlug}/orgstructure_pages`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const orgstructurePagesGet = (surveySlug, options) => transport({
  url: `/admin/surveys/${surveySlug}/orgstructure_pages`,
  method: methods.HTTP_GET,
  ...options,
});

export const orgstructurePagesBlocksUpdate = (surveySlug, data = {}, options) => transport({
  url: `/admin/surveys/${surveySlug}/orgstructure_pages/orgstructure_blocks`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const orgstructurePageTranslationsUpdate = (surveySlug, data = {}, options) => transport({
  url: `/admin/translations/surveys/${surveySlug}/orgstructure_pages`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const surveyFakesPost = (surveySlug, data = {}, options) => transport({
  url: `admin/surveys/${surveySlug}/fake_user_surveys`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const surveyFakesDelete = (surveySlug, data = {}, options) => transport({
  url: `/admin/surveys/${surveySlug}/fake_user_surveys`,
  method: methods.HTTP_DELETE,
  data,
  ...options,
});

export const externalSurveysGet = () => transport({
  url: '/admin/external_surveys',
  method: methods.HTTP_GET,
});

export const flexibleAnalyticsTabsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/tabs`,
  method: methods.HTTP_GET,
  ...options,
});

export const externalSurveyGet = (id) => transport({
  url: `/admin/external_surveys/${id}`,
  method: methods.HTTP_GET,
});

export const externalSurveyPost = (data) => transport({
  url: '/admin/external_surveys',
  method: methods.HTTP_POST,
  data,
});

export const externalSurveyDelete = (id) => transport({
  url: `/admin/external_surveys/${id}`,
  method: methods.HTTP_DELETE,
});

export const externalSurveyUpdate = (id, data) => transport({
  url: `/admin/external_surveys/${id}`,
  method: methods.HTTP_PUT,
  data,
});

export const adminCompaniesImportPost = (data = {}, options) => transport({
  url: 'admin/companies/import',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminFlexibleAnalyticsSingleIndexPost = (surveySlug, options) => transport({
  url: `admin/analytics/flexible/${surveySlug}/single_index/main`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminFlexibleAnalyticsSingleIndexComparisonPost = (surveySlug, options) => transport({
  url: `admin/analytics/flexible/${surveySlug}/single_index/comparison`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminFlexibleAnalyticsMainResultsSettingsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/main_results/visualization_settings`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminFlexibleAnalyticsVerticalFactorsPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/vertical_factors/main`,
  method: methods.HTTP_POST,
  ...options,
});
export const adminFlexibleAnalyticsVerticalFactorsComparisonPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/vertical_factors/comparison`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminFlexibleAnalyticsAdditionalQuestionsComparisonPost = (surveySlug, options) => transport({
  url: `admin/analytics/flexible/${surveySlug}/additional_questions/comparison`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminFlexibleAnalyticsAdditionalQuestionsMainPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/additional_questions/main`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminFlexibleAnalyticsSurveyParticipantsPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/survey_participants/main`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminFlexibleAnalyticsPdfSurveyParticipantsPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/pdf/survey_participants/main`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminFlexibleAnalyticsWelcomePagePost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/pdf/welcome_page/main`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminPdfSingleIndexDataPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/pdf/single_index/index_data`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminPdfSingleIndexCommentsPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/pdf/single_index/comments`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminPdfSingleIndexDynamicsPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/pdf/single_index/index_dynamics`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminPdfSingleIndexComparisonPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/pdf/single_index/index_comparison`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminPdfSingleIndexHeatmapPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/pdf/single_index/index_heatmap_comparisons`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminPdfChunksPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/pdf/chunks`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminPdfReportsS3LinkPost = (options) => transport({
  url: '/admin/analytics/flexible/pdf_reports',
  method: methods.HTTP_POST,
  ...options,
});

export const adminPdfReportsIdPut = (reportId, data = {}, options) => transport({
  url: `/admin/analytics/flexible/pdf_reports/${reportId}`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const adminFlexibleAnalyticsRespondentsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/respondents/anonymous`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminFlexibleAnalyticsVisualizationSettingsGet = (surveySlug, options) => transport({
  url: `admin/analytics/flexible/${surveySlug}/visualization_settings`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminFlexibleAnalyticsVisualizationSettingsUpdate = (surveySlug, data = {}, options) => transport({
  url: `admin/analytics/flexible/${surveySlug}/visualization_settings`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const adminFlexibleAnalyticsCompositeWidgetsQuestionsPost = (data = {}, options) => transport({
  url: 'admin/analytics/flexible/composite_widgets/question_list',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminFlexibleAnalyticsCompositeWidgetsAnswersListsGet = (surveySlug, data = {}, options) => transport({
  url: `admin/analytics/flexible/${surveySlug}/additional_questions/answers_lists`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminFlexibleAnalyticsCompositeWidgetsAnswersListGet = (surveySlug, data = {}, options) => transport({
  url: `admin/analytics/flexible/${surveySlug}/additional_questions/answers_list`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

// eslint-disable-next-line max-len,vue/max-len
export const adminFlexibleAnalyticsCompositeWidgetsAnswersComparisonGet = (surveySlug, data = {}, options) => transport({
  url: `admin/analytics/flexible/${surveySlug}/additional_questions/answers_comparison`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminFlexibleAnalyticsCompositeWidgetsDynamicsGet = (surveySlug, data = {}, options) => transport({
  url: `admin/analytics/flexible/${surveySlug}/additional_questions/answers_dynamics`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminFlexibleAnalyticsQuestionTitleGet = (id, options) => transport({
  url: `admin/analytics/flexible/question/${id}`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminFlexibleAnalyticsXlsxReports = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/xlsx_reports`,
  method: methods.HTTP_GET,
  responseType: 'blob',
  ...options,
});

export const adminFlexibleAnalyticsXlsxReportsPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/xlsx_reports`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminXlsxReportPost = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/xlsx/report/open-questions/${surveySlug}`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminXlsxReportStatusGet = (reportId, options) => transport({
  url: `/admin/analytics/surveys/xlsx/report/${reportId}/status`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminXlsxReportDownload = (reportId, options) => transport({
  url: `/admin/analytics/surveys/xlsx/report/${reportId}/download`,
  method: methods.HTTP_GET,
  responseType: 'blob',
  ...options,
});

export const adminQuestionTemplatesGet = (id, options) => transport({
  url: `/admin/question_templates/${id}`,
  method: methods.HTTP_GET,
  ...options,
});

export const baseQuestionsGet = (options) => transport({
  url: '/admin/base_questions',
  method: methods.HTTP_GET,
  ...options,
});

export const userQuestionsGet = (options) => transport({
  url: '/admin/user_questions',
  method: methods.HTTP_GET,
  ...options,
});

export const userQuestionCreate = (data, options) => transport({
  url: '/admin/user_question',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const userQuestionGet = (id, options) => transport({
  url: `/admin/user_question/${id}`,
  method: methods.HTTP_GET,
  ...options,
});

export const userQuestionUpdate = (id, data, options) => transport({
  url: `/admin/user_question/${id}`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const flexibleExternalSurveysGet = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/external_surveys`,
  method: methods.HTTP_GET,
  ...options,
});

export const flexibleExternalSurveysUpdate = (surveySlug, data, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/assign_external_surveys`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const adminFlexibleAnalyticsUserQuestionsGet = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/client_questions`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminFlexibleAnalyticsUserQuestionsWidgetsPost = (surveySlug, data, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/client_questions/list`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminFlexibleAnalyticsOtherUserQuestionsWidgetsPost = (surveySlug, data, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/client_questions/answers_list`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const flexibleAnalyticsSurveysGet = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/surveys`,
  method: methods.HTTP_GET,
  ...options,
});

export const flexibleAnalyticsAssignSurveys = (surveySlug, data, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/assign_surveys`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const adminFlexibleAnalyticsPrioritiesWidgetsPost = (surveySlug, data, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/priorities/main`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminFlexibleAnalyticsPrioritiesPracticesGet = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/priorities/hr_practices`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminAttachSurveyToPeriod = (periodSlug, surveySlug, options) => transport({
  url: `/admin/periods/${periodSlug}/surveys/${surveySlug}`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminPeriodsPost = (data = {}, options) => transport({
  url: '/admin/periods',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminPeriodsPut = (slug, data = {}, options) => transport({
  url: `/admin/periods/${slug}`,
  method: methods.HTTP_PUT,
  data,
  ...options,
});

export const adminComplexSurveysGet = (periodSlug, options) => transport({
  url: `/admin/periods/${periodSlug}/surveys`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminDeleteSurveyFromPeriod = (periodSlug, surveySlug, options) => transport({
  url: `/admin/periods/${periodSlug}/surveys/${surveySlug}`,
  method: methods.HTTP_DELETE,
  ...options,
});

export const adminPeriodsGet = (options) => transport({
  url: '/admin/periods',
  method: methods.HTTP_GET,
  ...options,
});

export const adminPeriodDelete = (options) => transport({
  url: '/admin/periods',
  method: methods.HTTP_DELETE,
  ...options,
});

export const flexibleAnalyticsSurveyGet = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/survey`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminMainFactorsGroupPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/main_results/factors_comparisons_group`,
  method: methods.HTTP_POST,
  ...options,
});

export const adminMainIndexesGroupPost = (surveySlug, options) => transport({
  url: `/admin/analytics/flexible/${surveySlug}/main_results/index_values_group `,
  method: methods.HTTP_POST,
  ...options,
});

export const adminAnalyticsSurveyStatistics = (surveySlug, options) => transport({
  url: `/admin/analytics/surveys/${surveySlug}/statistics`,
  method: methods.HTTP_GET,
  ...options,
});

export const adminTemplateCarousel = (templateId, options) => transport({
  url: `/admin/templates/${templateId}/carousel`,
  method: methods.HTTP_GET,
  ...options,
});

export const companyGuestsTokenGet = (companyId) => transport({
  url: `/company_guests/token`,
  method: methods.HTTP_GET,
  params: {
    "company_id": companyId
  }
});

export const releaseNotesGet = () => transport({
  url: `/admin/release_notes`,
  method: methods.HTTP_GET,
});

export const releaseNotesPost = (data = {}, options) => transport({
  url: `/admin/release_notes`,
  method: methods.HTTP_POST,
  data,
  ...options
});

export const releaseNotesDelete = (id, options) => transport({
  url: `/admin/release_notes/${id}`,
  method: methods.HTTP_DELETE,
  ...options
});

export const publishedReleaseNotesGet = (options) => transport({
  url: `/release_notes/`,
  method: methods.HTTP_GET,
  ...options
});

export const yandexGptIndexesFactors = (data = {}, options) => transport({
  url: `/yandex_gpt/indexes_factors`,
  method: methods.HTTP_POST,
  data,
  ...options
});

export const yandexGptOpenComments = (data = {}, options) => transport({
  url: `/yandex_gpt/open_comments`,
  method: methods.HTTP_POST,
  data,
  ...options
});